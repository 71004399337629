.trips-tab .ant-tabs-nav-list {
  width: 100%;
}
.trips-tab .ant-tabs-tab {
  display: block; /* centers text inside tabs */
  flex: 1;
  text-align: center;
  justify-content: center;
}
.trips-tab.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #95A0A9;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px;
}
.trips-tab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: red;
}
.trips-tab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #191a19 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px;
}
.trip-list .ant-card-body {
  padding: 0px;
  display: flex;
  height: fit-content;
}
.auth .ant-modal-content {
  padding: 0px !important;
}
.trip-image .ant-image {
  width: 100%;
}
.reccos-card .ant-card-body {
  padding: 0px;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 47.92%,
    rgba(0, 0, 0, 0.69) 100%
  );
}
.ant-modal-root .ant-modal-mask {
  background: rgba(0, 0, 0, 0.6);
}
.ant-carousel .slick-dots-bottom {
  bottom: 40px;
}
.ant-carousel .slick-dots li {
  width: 8px;
}
.ant-carousel .slick-dots li.slick-active {
  width: 8px;
}
.ant-carousel .slick-dots li button {
  height: 8px;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button {
  background: rgba(23, 131, 125, 1);
  opacity: 1;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  height: inherit;
  width: inherit;
}
.ant-skeleton.ant-skeleton-element {
  width: 100%;
}
.friends-card .ant-card-body {
  padding: 15px;
}

.ant-input-affix-wrapper:focus-within, .ant-input-affix-wrapper:hover {
  box-shadow: inherit;
  border-color: inherit;
}